import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { FrontPageComponent } from "./front-page/front-page.component";
import { CategoryComponent } from "./category/category.component";
import { CategoryListComponent } from "./category/category-list/category-list.component";
import { ProductDetailComponent } from "./category/product-detail/product-detail.component";
import { ShoppingCartComponent } from "./shopping-cart/shopping-cart.component";
import { EmptyComponent } from "./empty/empty.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { PaymentComponent } from "./payment/payment.component";

const routes: Routes = [
  { path: "", pathMatch: "full", component: FrontPageComponent },
  {
    path: "category",
    component: CategoryComponent,
    children: [
      { path: ":category", component: CategoryListComponent },
      { path: "product/:id", component: ProductDetailComponent },
    ],
  },
  { path: "shopping-cart", component: ShoppingCartComponent },
  { path: "payment", component: PaymentComponent },
  { path: "empty", component: EmptyComponent },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false, useHash: true }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
